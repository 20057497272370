<template>
  <b-card>
    <!-- form -->
    <b-form @submit.prevent="changePassword">
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Eski Şifre" label-for="account-old-password">
            <small class="text-danger">{{ status }}</small> <br>
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="currentPassword"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Eski Şifre"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password" label="Yeni Şifre">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPassword"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Yeni Şifre"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="Tekrar Yeni Şifre"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="confirmNewPassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="Yeni Şifre"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button type="submit" variant="primary" class="mt-1 mr-1">
            Kaydet
          </b-button>
          <b-button type="reset" variant="outline-secondary" class="mt-1">
            Vazgeç
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      email: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      status: "",
      passwordToggleIconNew: "EyeIcon",
      passwordToggleIconOld: "EyeIcon",
      passwordToggleIconRetype: "EyeIcon",

      passwordFieldTypeNew: "password",
      passwordFieldTypeOld: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  methods: {
    changePassword() {
  // Yeni şifre ve tekrar girilen şifrenin aynı olup olmadığını kontrol et
  // if (this.newPassword !== this.confirmNewPassword) {
  //   this.$bvToast.toast("", {
  //     variant: "danger",
  //   });
  //   return; // İşlemi durdur
  // }

  axios
    .post("/api/Users/ChangePassword", {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword,
    })
    .then((authResponse) => {
    //  console.log("authResponse:", authResponse); 
      if (authResponse.data.success) {
        // Mevcut şifrenin doğru girildiğini kontrol et
        return axios.post("/api/Auth/Login", {
          password: this.newPassword,
        }).then((r)=>{
          this.status = r.data.message; 
        });
        
      } else {
        this.status = authResponse.data.message; 
      }
    })
    .then((loginResponse) => {
    //  console.log("Login response:", loginResponse);
    })
    .catch((error) => {
      console.error("Şifre değiştirme hatası:", error);
    });
},


    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
      this.passwordToggleIconOld =
        this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
      this.passwordToggleIconNew =
        this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
      this.passwordToggleIconRetype =
        this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
