<template>
  <b-card v-if="Object.keys(blogEdit).length" class="blog-edit-wrapper">
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group label="Şirket" label-for="blog-edit-title" class="mb-2">
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="location"
              :options="option"
            />
          </b-form-group>
        </b-col>
        <!--Email -->
        <b-col cols="6">
          <b-form-group label="Email" label-for="h-email">
            <b-form-input
              id="h-email"
              type="email"
              placeholder="info@ybm.com"
              v-model="email"
            />
          </b-form-group>
        </b-col>

        <!--Phone-->
        <b-col cols="6">
          <b-form-group label="Telefon" label-for="h-number" class="mt-1">
            <b-form-input
              id="h-number"
              type="text"
              placeholder="+90 260 000 00 00"
              maxlength="17"
              v-model="phone"
            />
          </b-form-group>
        </b-col>

        <!--Adress-->
        <b-col cols="6">
          <b-form-group label="Adres" label-for="blog-edit-title">
            <b-form-textarea
              id="textarea-default"
              placeholder="Adres"
              rows="2"
              v-model="address"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="mt-50">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 ml-1"
            @click="saveContant()"
          >
            Kaydet
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="clearData()"
          >
            Vazgeç
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BFormTextarea,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "../../Edit/form-utils";
import { ref } from "@vue/composition-api";
import axios from "axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BFormTextarea,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      blogFile: null,
      selected: [],
      option: [],
      snowOption: {
        theme: "snow",
      },
      blogEdit: {
        blogTitle: "",
        featuredImage: require("@/assets/images/banner/contact.jpg"),
      },
      id: null,
      email: "",
      phone: "",
      address: "",
      location: "",
      message: null,
      // deneme:""
    };
  },
  created() {
    this.getAllData();
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    };
  },
  watch: {
    selected(newValue) {
      if (newValue && newValue.id) {
        this.getAllData(newValue.id); // niye newValue.id aldı ?
      }
    },
  },
  methods: {
    getAllData() {
      //   console.log(this.selected.id);
      //  console.log('here2')
      const languageCode = localStorage.getItem("language") === "2" ? 2 : 1;
      axios
        .get(`/api/Informations/GetListByLanguage?language=${languageCode}`)
        .then((result) => {
          if (result.data.success) {
            result.data.data.forEach((element) => {
              if (this.selected.id == element.id) {
                //  console.log('elementid: ' + element.id)
                //  console.log(element)
                this.email = element.email;
                this.phone = element.phone;
                this.address = element.address;
                this.location = element.location;
              }
            });
            this.option = result.data.data;
          } else {
            console.error("API yanıtında başarısızlık:", result.data.message);
          }
        })
        .catch((error) => {
          console.error("Get Catch'e düştü", error);
        });
    },
    /* updateBtn() {
    //  console.log("id:" + this.id);
      const newSliderInfo = {
        id: this.id,
        firstName: this.firstName,
        lastName: this.email,
        phone: this.phone,
        address: this.address,
        //  message:this.message,
        //  email:this.deneme
      };
      axios
        .post("/api/Communications/Update", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },*/
    clearData() {
      //  console.log('here')
      this.email = null;
      this.phone = null;
      this.address = null;
    },
    saveContant() {
      if (!this.selected || !this.selected.id) {
        console.error("Geçersiz seçilen nesne");
        return;
      }

      const newSliderInfo = {
        id: this.selected.id,
        email: this.email,
        phone: this.phone,
        address: this.address,
        location:this.location
      };

      axios
        .post("/api/Informations/Update", newSliderInfo)
        .then((result) => {
          if (result.data.success) {
            this.$bvToast.toast("GÜNCELLENDİ", {
              title: `ANASAYFA`,
              variant: "success",
            });
          } else {
            this.$bvToast.toast("GÜNCELLENEMEDİ", {
              title: `ANASAYFA`,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          console.error("Güncelleme Hatası", error);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
